import React from 'react';

const Create = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <button className="btn btn-dark mx-2" type="submit">
        Create QR
      </button>
      <a href="https://app.qrexperiences.com/" target="_blank" rel="noreferrer">
        <button className="btn btn-dark mx-2" type="button">
          Generate Images with AI
        </button>
      </a>
    </div>
  );
};

export default Create;
